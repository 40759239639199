import React from 'react'
import {Helmet} from "react-helmet";

const HelmetSettings = () => {
  return (
    <div>
        <Helmet>
            <html lang="cs" />
            <title>Svááča Holešovice</title>
            <meta name="description" content="Spěcháte do školy či do práce a nestíháte si udělat svačinku? Stavte se u nás, slovo sváča máme v názvu, takže za kvalitu ručíme!" />
        </Helmet>
    </div>
  )
}

export default HelmetSettings