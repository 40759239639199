import React from 'react'
import Person_image from '../assets/About_img.webp'

const About = () => {
  return (
    <div name="about" className='w-full min-h-screen flex items-center justify-center'>
      <div className='mt-20 max-w-[1400px] m-auto grid px-4 lg:grid-cols-2 gap-3 sm:pt-0 sm:pb-0'>

        <div name="about-res" className='order-2 md:order-1'>
          <img className='p-2 object-cover h-[80vh] m-auto' src={Person_image} alt="/"/>
        </div>

        <div className=' flex flex-col h-full justify-center order-1 md:order-2'>
          <h3 name='hero-res' className='p-2 text-5xl font-bold'>
            O NÁS
          </h3>
          <p className='pt-0 p-2 text-xl text-justify pt-3'>Jsme malé rodinné bistro v Holešovicích kde se zaměřujeme na svááči s sebou . Slané a sladké dobroty, narozeninové dorty i občerstvení pro oslavy, firemní akce nebo svatby, připravované majitelkou Karolínou a jejím týmem. Vše z kvalitních surovin , domácí a dělané s láskou .</p>
        </div>
      </div>
    </div>
  )
}

export default About