import About from "./components/About";
import Bakery from "./components/Bakery";
import Cakes from "./components/Cakes";
import Contacts from "./components/Contacts";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import HelmetSettings from "./components/HelmetSettings";
import Navbar from "./components/Navbar";
import Orders from "./components/Orders";
import Sweet from "./components/Sweet";

function App() {
  return (
    <div className="App">
        <HelmetSettings/>
        <Navbar/>
        <About/>
        <Bakery/>
        <Sweet/>
        <Cakes/>
        <Orders/>
        <FAQ/>
        <Contacts/>
        <Footer />
    </div>
  );
}

export default App;
