import React from 'react'
import EU from "../assets/EU.png"
import MK from "../assets/MK.jpg"
import NPO from "../assets/NPO.png"

const Footer = () => {
  return (
    <div className=''>
        <div className='max-w-[90vh] m-auto px-4'>
            <p className='text-xs'>Projekt je financován  Evropskou unií – Next Generation EU.</p>
            <p className='text-xs'>Název projektu:   Foto pro Svááča</p>
            <p className='text-xs'>Číslo projektu:     0380000890</p>
            <p className='text-xs'>Cíl projektu:        Podpora vzájemné spolupráce kreativních profesionálů Celostátní galerie kreativců a podnikatelského sektoru v oblasti kreativních inovací.
                Cílem projektu je kvalitní fotografická propagace.</p>
        </div>
        <div className='flex flex-row flex-wrap justify-between bottom-0 max-w-[90vh] m-auto place-items-center py-2'>
          <div className='px-4'>
              <img src={EU} alt="/" className='inset-x-0 bottom-0 max-w-[15vh] m-auto'/>
          </div>
          <div className='px-4'>
              <img src={MK} alt="/" className='inset-x-0 bottom-0 max-w-[15vh] m-auto'/>
          </div>
          <div className='px-4'>
              <img src={NPO} alt="/" className='inset-x-0 bottom-0 max-w-[15vh] m-auto'/>
          </div>
        </div>
    </div>
  )
}

export default Footer